import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
{/* <script src="../path/to/flowbite//dist/flowbite.js"></script> */}
import 'flowbite/dist/flowbite.js'
import 'flowbite';
import './tailwind.css'

if (process.env.VUE_APP_ENV == 'PRODUCTION'){
	// axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
	axios.defaults.baseURL = 'https://09ba-175-143-184-209.ap.ngrok.io'
} else {
	// axios.defaults.baseURL = 'http://127.0.0.1:8000'
	// axios.defaults.baseURL = 'https://qrflalfmbu5c3tv4dsjtmfozvi0dglui.lambda-url.ap-southeast-1.on.aws'
	axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const app = createApp(App)
app.use(store)
	.use(router)
	.mount('#app')
