<template>
	<div class="">
		<div class="h-screen min-h-screen">
			<div class="inline md:hidden h-20 bg-slate-800 flex items-center justify-center">
				<div class=" w-full bg-white py-2 flex justify-center">
					<span class="font-bold text-xl text-center">Interview Analytics Platform</span>
				</div>
			</div>
			
			<div class="grid grid-cols-1 md:grid-cols-2 h-full">
				<div class="w-full h-full bg-slate-800 flex py-8 md:py-0 relative">
					<div class="hidden md:inline w-full bg-white absolute top-12 pl-4 py-2">
						<span class="font-bold text-3xl xl:text-5xl">Interview Analytics Platform</span>
					</div>
					<div class="bg-white text-slate-800 shadow-2xl  shadow-white/10 rounded-sm m-auto w-5/6 max-w-lg max-h-2/3 flex flex-col p-8">
						<div class="flex flex-col items-start">
							<span class="text-3xl font-bold">For Job Seekers</span>
							<span class="text-sm font-light mt-2">Do mock interview and get feedbacks instantly. Improve yourself and 
								have a chance to being shortlisted for job applications. Log in to start!</span>
						</div>
						
						<div class="mt-12">
							<img class="object-cover w-full h-48" src="../assets/starwars-1.jpg" alt="">
						</div>
						<div class="flex flex-col pt-3 rounded-sm mt-4">
							<div class="w-full" id="firebaseui-auth-container"></div>
							<div id="loader">Loading...</div>
						</div>
					</div>
				</div>
				<div class="w-full h-full bg-white flex py-8 md:py-0">
					<div class="bg-slate-800 text-white shadow-2xl rounded-sm m-auto w-5/6 max-w-lg max-h-2/3 flex flex-col p-8">
						<div class="flex flex-col items-start">
							<span class="text-3xl font-bold">For Talent Recruiters</span>
							<span class="text-sm font-light mt-2">Review and shortlist top-rated talents evaluated by our AI-driven
								interview evaluation platform and boost your recruiting process. Log in to explore!</span>
						</div>
						<div class="mt-12">
							<img class="object-cover w-full h-48" src="../assets/starwars-2.jpg" alt="">
						</div>
						<div class="flex flex-col items-start mt-8 shadow-md shadow-white/20">
							<router-link class="w-full" :to="{name : 'recruiter'}">
								<div class="w-full flex justify-center items-center py-1.5 bg-white shadow-md rounded-sm">
									<span class="text-slate-800 text-base font-semibold">
										Sign in
									</span>
								</div>
							</router-link>
							<!-- <div class="w-full cursor-not-allowed" :to="{name : 'recruiter'}" >
								<div class="w-full flex justify-center items-center py-1.5 bg-white shadow-md rounded-sm">
									<span class="text-slate-800 text-base font-semibold">
										Sign in
									</span>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import store from '../store/index'
import axios from 'axios'
import { Dropdown } from 'flowbite-vue'
import { doc, getDoc, setDoc  } from "firebase/firestore"

import { firebase, db, auth, ui } from '../firebase/config'


export default {
	name: 'LoginView',
	components: {
		Dropdown
	},
	setup(){
		const router = useRouter()

		const uiConfig = {
			signInFlow: 'redirect', //'redirect' 'popup'
			signinSuccessUrl: 'http://localhost:8080/',
			signInOptions: [
				firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				// firebase.auth.FacebookAuthProvider.PROVIDER_ID,
			],
			callbacks: {
				signInSuccessWithAuthResult: function (authResult) {
					let userAuthData = (({ uid, displayName, email, phoneNumber, photoURL }) => ({ uid, displayName, email, phoneNumber, photoURL }))(authResult.user);
					userAuthData.trial = 0
					// create user in db if doesn't exist
					getDoc(doc(db, 'users', userAuthData.uid)).then((docSnap) => {
						if (!docSnap.exists()){
							setDoc(doc(db, 'users', userAuthData.uid),
								(({ uid, ...o }) => o)(userAuthData)
							)
						}
					})
					store.commit('setUserAuth', userAuthData)
					// return false to prevent page refresh
					return false;
				},
				uiShown: function() {
					// The widget is rendered.
					// Hide the loader.
					document.getElementById('loader').style.display = 'none';
				}
			}
		}

		onMounted(()=>{
			try {
				axios.get('/ping')
				ui.start('#firebaseui-auth-container', uiConfig)
				// remove default google login button colour
				setTimeout(() => { 
					let googleLoginButton = document.querySelector("[data-provider-id='google.com']")
					googleLoginButton.removeAttribute('style')
				}, 500);
			}
			catch(error){

			}
		})
		

		return {  }
	}
	
}
</script>

<style>



.firebaseui-card-content{
	@apply p-0
}

.firebaseui-container{
	@apply m-0 max-w-full 
}

.firebaseui-idp-button{
	@apply w-full max-w-full flex justify-center items-center bg-slate-800 hover:bg-slate-700
}

.mdl-button--raised{
	@apply shadow-md
}

.firebaseui-idp-list{
	@apply my-0 grid grid-cols-1 divide-y-[1rem] divide-transparent
}

.firebaseui-idp-list>.firebaseui-list-item, .firebaseui-tenant-list>.firebaseui-list-item:last-child{
	@apply mb-0
}

.firebaseui-idp-text{
	@apply text-base font-medium
}
.firebaseui-idp-google>.firebaseui-idp-text {
	@apply text-white
}

</style>