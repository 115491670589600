<template>
	<div>
		<div class="flex items-center justify-between">
			<span class="text-lg sm:text-xl text-slate-800">Filler Word Performance</span>
			<div class="border-4 border-slate-500 rounded-full h-10 sm:h-12 w-10 sm:w-12  flex">
				<span class="m-auto font-bold text-base sm:text-xl text-slate-700">{{score}}</span>
			</div>
		</div>
		<div class="h-8 sm:h-12 mt-2">
			<canvas id="fillerWordChart"></canvas>
		</div>
		<div class="w-full px-4" >
			<svg class="w-6 h-6 stroke-slate-800 relative" :style="`left: ${Math.min(100 - score, 99)}%`"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
			</svg>
		</div>
		<span class="mt-2 text-sm">
			{{feedbackText}}
		</span>
	</div>
</template>
<script>
import { onMounted, ref } from 'vue'
import Chart from 'chart.js/auto'

export default {
	name: 'FillerWord',
	props: ['score'],
	setup(props){
		const feedbackText = ref('')
		const feedbackDict = [
			"Minimizing the use of filler words during your interview will stop you from rambling and make you appear more confident. Recruiters often struggle with comprehending interviewees who use an excessive amount of filler words in their answers.",
			"You’re close! Try reducing the use of filler words to make your answers more concise and coherent.",
			"Great job! You used impactful words in your answers which made it concise and comprehensible to the interviewer"
		]

		let chart = null

		const generateProgress = (totalBar, firstTreshold, secondThreshold) => {
			const data = Array(totalBar).fill(1)
			let backgroundColor = Array(totalBar)
			backgroundColor = backgroundColor.fill('rgba(75, 192, 192, 0.8)', 0, parseInt(firstTreshold*totalBar))
			backgroundColor = backgroundColor.fill('rgba(255, 206, 86, 0.8)', parseInt(firstTreshold*totalBar), parseInt(secondThreshold*totalBar))
			backgroundColor = backgroundColor.fill('rgba(255, 99, 132, 0.8)', parseInt(secondThreshold*totalBar), totalBar)
			return { data, backgroundColor }
		}

		const renderChart = () => {
			const ctxChart = document.getElementById('fillerWordChart').getContext('2d')
			const chart = new Chart(ctxChart, {
				type: 'bar',
				data: {
					labels: [],
					datasets: [{
						label: '',
						data: [],
						fill: true,
						// borderWidth: 1,
						barPercentage: 1.0,
						categoryPercentage: 0.95,
					}]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					interaction: {
						mode: 'nearest',
						axis: 'x',
						intersect: false
					},
					plugins: {
						legend: false,
						tooltip: {
							enabled: false
						}
					},
					
					scales: {
						x: {
							display: false,
						},
						y:{
							display: false,
						}
					}
				},
			})
			return chart
		}

		onMounted(() => {
			const { data, backgroundColor } = generateProgress(50, 0.4, 0.7)
			chart = renderChart()
			chart.data.labels = data
			chart.data.datasets[0].data = data
			chart.data.datasets[0].backgroundColor = backgroundColor
			chart.update()

			if (props.score <= 30){
				feedbackText.value = feedbackDict[0]
			} else if (props.score <= 60){
				feedbackText.value = feedbackDict[1]
			} else {
				feedbackText.value = feedbackDict[2]
			}
		})
		return { feedbackText }
	}
}
</script>
