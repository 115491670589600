import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { getStorage, ref } from "firebase/storage";

// Create a root reference


import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { getAuth } from "firebase/auth"


const firebaseConfig = {
	apiKey: "AIzaSyDKbPyxV8qscaOkXs7-wjPB2Bdq7zVnBkA",
	authDomain: "iv-video-analytics.firebaseapp.com",
	databaseURL: "https://iv-video-analytics-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "iv-video-analytics",
	storageBucket: "iv-video-analytics.appspot.com",
	messagingSenderId: "172260795496",
	appId: "1:172260795496:web:d8bfa4393cfed37af007dd"
};

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const storage = getStorage()

const auth = getAuth()
const ui = new firebaseui.auth.AuthUI(firebase.auth());

export { firebase, db, storage, auth, ui } 