import { createRouter, createWebHistory } from 'vue-router'
import CandidateView from '../views/CandidateView.vue'
import RecruiterView from '../views/RecruiterView.vue'
import LoginView from '../views/LoginView.vue'

import store from '../store/index'


const requireAuth = (to, from, next) => {
	let user = store.state.userAuth
	if (user != null){
		next()
	} else{
		next({name: 'login'})
	}
}

const routes = [
	{
		path: '/',
		name: 'candidate',
		component: CandidateView,
		beforeEnter: requireAuth
	},{
		path: '/recruiter',
		name: 'recruiter',
		component: RecruiterView
	},{
		path: '/login',
		name: 'login',
		component: LoginView
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
