<template>
	<div @click="toggleShowVideo" class="bg-violet-100 hover:bg-violet-200 flex flex-col">
		<div class="p-4 flex flex-col-reverse sm:flex-row justify-between sm:h-44">
			<div class="h-full flex flex-col justify-between mt-2 sm:mt-0">
				<div>
					<div>
						<span class="text-lg sm:text-xl font-bold">{{user.displayName}}</span>
						<span> - </span>
						<span class="text-lg sm:text-xl font-bold">{{user.overall_score}}</span>
					</div>
					<div class="flex flex-col text-xs sm:text-sm">
						<span>Smile score: {{user.smile_score}}</span>
						<span>Eye contact score: {{user.eye_contact_score}}</span>
						<span>Filler word score: {{user.filler_word_score}}</span>
					</div>
				</div>
				<div class="">
					<span class="text-sm sm:text-base">Contact: </span>
					<a class="text-blue-700 underline text-sm sm:text-base" :href="`mailto:${user.email}`">{{user.email}}</a>
				</div>
			</div>
			<div class="h-full rounded-sm">
				<img class="h-full aspect-video object-cover" :src="user.thumbnail_storage_path" alt="">
			</div>
		</div>
		<div v-if="showVideo" class="w-full aspect-video px-4 pb-4">
			<video class="w-full aspect-video" controls>
				<source :src="user.video_storage_path"
					type="video/mp4">
				Your browser does not support the video tag.
			</video>
		</div>

	</div>
	
</template>

<script>
import { ref } from 'vue';


export default {
	name: 'ProfileCard',
	props: ['user'],
	setup(){
		const showVideo = ref(false)

		const toggleShowVideo = () => {
			showVideo.value = !showVideo.value
		}

		return { showVideo, toggleShowVideo }
	}
}
</script>