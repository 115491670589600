<template>
	<div class="pb-8 pb-8 px-4 md:px-0 text-slate-800">
		<div class="max-w-3xl mx-auto min-h-screen pt-8 flex flex-col">
			<div class="flex justify-between items-center">
					<div class="flex flex-col">
						<span class="text-3xl font-semibold">Hey {{name}}!</span>
						<span class="font-light">Get quick feedbacks on your interview performance.</span>
					</div>
					<!-- <router-link :to="{name : 'login'}"> -->
						<div @click="handleSignOut" class="bg-rose-600 hover:bg-rose-400 p-4 text-center text-white rounded-sm text-sm">
							SIGN OUT
						</div>
					<!-- </router-link> -->
				</div>
			<div v-if="showStats">
				<div class="flex flex-col mt-4 p-4 bg-white rounded-sm">
					<div class="w-full aspect-video">
						<!-- <img class="w-full" src="https://firebasestorage.googleapis.com/v0/b/iv-video-analytics.appspot.com/o/thumbnails%2F115422187520795107049.jpg?alt=media&token=a3a3bb9f-2b21-44f1-94a5-e2c8b3122862" alt=""> -->
						<video class="w-full" controls>
							<source :src="userData.video_storage_path"
								type="video/mp4">
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
				<div class="flex flex-col mt-1 p-4 bg-white rounded-sm">
					<div class="text-xl sm:text-2xl flex justify-center items-center mb-2 sm:mb-0">
						<span>Overall Score:</span>
						<div class="bg-slate-700 rounded-full h-10 sm:h-12 w-10 sm:w-12 flex ml-4">
							<span class="m-auto font-bold text-base sm:text-xl text-white">{{userData.overall_score}}</span>
						</div>
					</div>
					<Smile class="" :score="userData.smile_score"/>
					<EyeContact class="mt-8" :score="userData.eye_contact_score"/>
					<FillerWord class="mt-8" :score="userData.filler_word_score"/>
				</div>
			</div>
			
			<div class="flex flex-col mt-1 p-4 bg-white rounded-sm">
				<span class="text-xl">Submit New Video</span>
				<div class="mt-2 flex flex-col text-sm">
					<span class="font-bold">For your interview, please answer these questions:</span>
					<span class="font-thin">1. Introduce yourself.</span>
					<span class="font-thin">2. Where do you see yourself in the next 5 years.</span>
					<span class="font-thin">3. What is your strength.</span>
				</div>
				<div class="flex justify-center items-center w-full mt-4">
					<label for="dropzone-file" class="flex flex-col justify-center items-center w-full h-48 sm:h-64 bg-transparent rounded-sm border-2 border-slate-300 border-dashed cursor-pointer ">
						<div  v-if="file == null" class="flex flex-col justify-center items-center pt-5 pb-6">
							<svg aria-hidden="true" class="mb-3 w-8 sm:w-10 h-8 sm:h-10 text-slate-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
							<div class="mb-2 text-xs sm:text-sm text-slate-500">
								<span class="font-semibold">Click to upload</span>
								<!-- <span> or </span>
								<span class="font-semibold">Drag and drop</span> -->
							</div>
							
							<!-- <p class="mb-2 text-sm text-slate-500"><span class="font-semibold">Click to upload</span> or drag and drop</p> -->
							<p v-if="!wrongFileText" class="text-xs">MP4, MOV, WEBM or FLV (MAX. 200MB / 5 MINUTES)</p>
							<p v-else  class="text-xs text-rose-600 font-bold">MP4, MOV, WEBM or FLV (MAX. 200MB / 5 MINUTES)</p>
						</div>
						<div v-else class="flex flex-col justify-center text-center">
							<span class="text-base text-slate-700">{{file.name}}</span>
							<span class="text-xs text-slate-500">{{generateFileSizeText(file.size)}} MB</span>
							<span></span>
							<span class="text-sm"></span>
						</div>
						<input ref="fileRef" @change="onFileChanged" id="dropzone-file" type="file" class="hidden" />
					</label>	
				</div>
				<div class="w-full flex justify-between mt-2 items-center">
					<span class="text-sm">Select your field of expertise</span>
					<dropdown placement="top" :text="expertiseField" class="dropdown-button ">
						<list-group>
							<list-group-item v-for="field in expertiseFieldList" @click="expertiseField = field">
								<div class="px-4 py-2 bg-slate-100 hover:bg-white ">
									<span>{{field}}</span>
								</div>
							</list-group-item>
						</list-group>
					</dropdown>
				</div>
				<div v-if="isProcessing" class="mt-4">
					<div class="w-full bg-slate-200 rounded-full h-2.5">
						<div class="bg-blue-400 h-2.5 rounded-full" :style="`width: ${progressPercentage}%`"></div>
					</div>
					<div class="flex justify-between">
						<span class="text-sm font-medium text-slate-800">{{progressText}}</span>
						<span class="text-sm font-medium text-slate-800">{{progressPercentage}}%</span>
					</div>
				</div>
				
				<div class="flex justify-end mt-2 sm:mt-4">
					<div @click="clearFile" class="cursor-pointer border-slate-400 border bg-transparent hover:bg-slate-200 text-slate-700 p-4 px-6 w-fit mt-2 text-sm rounded-sm mr-2">
						Clear
					</div>
					<div @click="submitFile" :class="file==null | expertiseField=='Expertise'?'bg-gray-600 cursor-not-allowed': 'bg-slate-800 hover:bg-slate-600 cursor-pointer'" class= "  p-4 w-fit mt-2 text-white text-sm rounded-sm ">
						<span v-if="!isProcessing">Submit For Review</span>
						<div v-else role="status" class="flex items-center">
							<svg aria-hidden="true" class="mr-2 w-6 h-6 text-transparent animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
								<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
							</svg>
							<!-- <span class="">Evaluating...</span> -->
						</div>
					</div>
				</div>
				<div v-if="isProcessing" class="text-end text-xs mt-2">
					<span>This process could take up to a few minutes.</span>
				</div>
				<div v-if="badRequestText != ''" class="text-end text-rose-600 mt-2">
					<span>{{badRequestText}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { Dropdown } from 'flowbite-vue'

import store from '../store/index'
import { signOut } from "firebase/auth"
import { auth, db, storage } from '../firebase/config'
import { doc, getDoc, updateDoc  } from 'firebase/firestore'
import { ref as storageRef, getDownloadURL, uploadBytesResumable, deleteObject, listAll  } from "firebase/storage"

import Smile from '@/components/Smile.vue'
import EyeContact from '@/components/EyeContact.vue'
import FillerWord from '@/components/FillerWord.vue'

import nanoMetadata from 'nano-metadata'

export default {
	name: 'CandidateView',
	components: {
		Smile,
		EyeContact,
		FillerWord,
		Dropdown
	},
	setup(){
		const name = ref('')
		const showStats = ref(false)
		const userData = ref()
		const fileRef = ref(null)
		const file = ref(null)
		const isProcessing = ref(false)
		const progressPercentage = ref(0)
		const progressText = ref('')
		const expertiseField = ref('Expertise')
		const wrongFileText = ref(false)
		const badRequestText = ref('')
		
		const expertiseFieldList = [
			'Architecture',
			'Engineering and Computer Science',
			'Arts and Entertainment',
			'Business, management and administration',
			'Education',
			'Law & Public Policy',
			'Health and Medicine'
		]

		const setName = () => {
			const displayName = store.state.userAuth.displayName
			const nameArray = displayName.split(' ')
			if (nameArray.length == 1){
				return nameArray[0]
			} else {
				return nameArray[0] + " " + nameArray[1]
			}
		}

		const readUser = async () => {
			const docSnap = await getDoc(doc(db, 'users', store.state.userAuth.uid))
			userData.value = docSnap.data()
		}

		const handleSignOut = async () => {
			signOut(auth).then(() => {
				// Sign-out successful.
				store.commit('setUserAuth', null)
			}).catch((error) => {
				console.log(error)
			});
		}

		onMounted(async () => {
			name.value = setName()
			await readUser()
			if ('overall_score' in userData.value){
				showStats.value = true
			}
		})

		watch(userData, () => {
			try {
				if ('overall_score' in userData.value){
					showStats.value = true
				}
			} catch(error) {
				console.log(error)
			}
			
		})

		const onFileChanged = async () => {
			console.log(fileRef.value.files[0])
			if (fileRef.value.files == null){
				file.value = null
			} else if (fileRef.value.files[0].size > 200000000 ) {
				// if more than 200 MB
				fileRef.value.files == null
				file.value = null
				wrongFileText.value = true
				await new Promise(r => setTimeout(r, 5000));
				wrongFileText.value = false
			} else if (!fileRef.value.files[0].type.includes('video')) {
				// wrong not video
				fileRef.value.files == null
				file.value = null
				wrongFileText.value = true
				await new Promise(r => setTimeout(r, 3000));
				wrongFileText.value = false
			} else {
				file.value = fileRef.value.files[0]
				const duration = await nanoMetadata.video.duration(file.value)
				if (duration > 300) {
					fileRef.value.files == null
					file.value = null
					wrongFileText.value = true
					await new Promise(r => setTimeout(r, 3000));
					wrongFileText.value = false
				}	
			}
		}

		const clearFile = () => {
			fileRef.value = null
			file.value = null
		}

		const generateFileSizeText = (size) => {
			if (size<10000){
				return parseInt(size/1000*10, 10)/10
			} else {
				return parseInt(size/1000000*10, 10)/10
			}
		}

		// const moveFile = async (oldEntirePath, newEntirePath) {
		// 	var bucket = admin.storage().bucket();

		// 	return bucket.file(oldEntirePath).move(newEntirePath)
		// 	.then(resp => {

		// 		let bucketPart = resp[1].resource.bucket
		// 		let namePart = resp[0].id
		// 		let tokenPart = resp[1].resource.metadata.firebaseStorageDownloadTokens

		// 		const url = "https://firebasestorage.googleapis.com/v0/b/"+ bucketPart +"/o/"+ namePart +"?alt=media&token=" + tokenPart
		// 		console.log(`Image url = ${url}`)
		// 		return url

		// 	})
		// 	.catch(err => {
		// 		console.log(`Unable to upload image ${err}`)
		// 	})
		// }

		const submitFile = async () => {
			if (file.value != null && expertiseField.value != 'Expertise'){
				progressPercentage.value = 0
				isProcessing.value = true
				// upload to video Firebase Storage
				progressText.value = 'Reviewing file...'
				progressPercentage.value = 5
				const fileName = file.value.name
				const fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)
				// const videoRef = storageRef(storage, `videos/${store.state.userAuth.uid}.${fileExtension}`)
				userData.value.trial++
				await updateDoc(doc(db, 'users', store.state.userAuth.uid), {'trial': userData.value.trial})

				const videoRef = storageRef(storage, `videos/${store.state.userAuth.uid}/${userData.value.trial}.${fileExtension}`)
				progressText.value = 'Uploading file to the server...'
				const uploadTask = uploadBytesResumable(videoRef, file.value)
				await uploadTask.on('state_changed',
					(snapshot) => {
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
						progressPercentage.value = parseInt(5 + progress*45)
						switch (snapshot.state) {
						case 'paused':
							break;
						case 'running':
							break;
						}
					}, 
					(error) => {
						// A full list of error codes is available at
						// https://firebase.google.com/docs/storage/web/handle-errors
						switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;
						case 'storage/canceled':
							// User canceled the upload
							break;
						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;
						}
					}, 
					async () => {
						// Upload completed successfully, now we can get the download URL
						progressPercentage.value = 55
						progressText.value = 'Evaluating interview performance...'
						const videoUrl = await getDownloadURL(uploadTask.snapshot.ref)
						axios.get( '/evaluate-video',
							{
								params: {
									'user_id': store.state.userAuth.uid,
									'video_url': videoUrl
								}
							}
							).then( async (response) => {
								let newData = response.data
								newData.expertise_field = expertiseField.value
								await updateDoc(doc(db, 'users', store.state.userAuth.uid), response.data)
								progressPercentage.value = 95
								progressText.value = 'Finishing up...'
								await new Promise(r => setTimeout(r, 3000));
								clearFile()
								await readUser()
								progressPercentage.value = 100
								progressText.value = 'Done!'
								await new Promise(r => setTimeout(r, 1000));
								isProcessing.value = false

								// delete all old uploadd
								const listRef = storageRef(storage, `videos/${store.state.userAuth.uid}/`);
								// Find all the prefixes and items.
								listAll(listRef)
									.then((res) => {
										res.prefixes.forEach((folderRef) => {
										// All the prefixes under listRef.
										// You may call listAll() recursively on them.
											// console.log(folderRef)
										});
										res.items.forEach((itemRef) => {
										// All the items under listRef.
											if (itemRef._location.path_ != `videos/${store.state.userAuth.uid}/${userData.value.trial}.${fileExtension}`){
												deleteObject(itemRef).then(() => {
													// File deleted successfully
													console.log(`deleted ${itemRef._location.path_}`)
												}).catch((error) => {
													// Uh-oh, an error occurred!
												});
											}
											console.log(itemRef._location.path_)
										});
									}).catch((error) => {
										console.log(error)
										// Uh-oh, an error occurred!
									});
							})
							.catch(async (error) => {
								console.log(error.response.data.detail)
								isProcessing.value = false
								badRequestText.value = error.response.data.detail
								fileRef.value.files == null
								file.value = null
								await new Promise(r => setTimeout(r, 5000));
								badRequestText.value = ''

								// delete latest  upload
								deleteObject(videoRef).then(() => {
									// File deleted successfully
									console.log(`deleted ${videoRef._location.path_}`)
								}).catch((error) => {
									console.log('error')
									// Uh-oh, an error occurred!
								});
							}
						)
					}
				)
			}
		}

		// this function will send the video to the backend. not use because Lambda can't handle big file size.
		// const submitFile = async () => {
		// 	if (file.value != null){
		// 		isProcessing.value = true
		// 		let formData = new FormData()
		// 		formData.append('file', file.value)

		// 		axios.post( '/evaluate-video',
		// 			formData,
		// 			{
		// 				headers: {
		// 					'Content-Type': 'multipart/form-data'
		// 				},
		// 				params: {
		// 					'user_id': store.state.userAuth.uid
		// 				}
		// 			}
		// 			).then( async (response) => {
		// 				await updateDoc(doc(db, 'users', store.state.userAuth.uid), response.data)
		// 				clearFile()
		// 				await readUser()
		// 				isProcessing.value = false
		// 			})
		// 			.catch((error) => {
		// 				console.log(error)
		// 				isProcessing.value = false
		// 			})
		// 		}
		// }

		return { handleSignOut, onFileChanged, clearFile, generateFileSizeText, submitFile, 
			isProcessing, fileRef, file, name, showStats, userData, progressPercentage, progressText, 
			expertiseField, expertiseFieldList, wrongFileText, badRequestText }
	}
}
</script>

<style>
.dropdown-button button{
	@apply rounded-sm px-4 flex justify-between w-48 bg-white text-slate-800 border-2 hover:text-slate-800 hover:bg-white focus:ring-0 border-slate-300 border-dashed
}
</style>