<template>
	<div class="pb-8 px-4 md:px-0">
		<div class="max-w-3xl mx-auto min-h-screen pt-8 flex flex-col">
			<div class="flex justify-between items-center">
				<div class="flex flex-col">
					<span class="text-3xl font-semibold">Welcome Recruiters!</span>
					<span class="font-light">Review and shortlist top job candidates.</span>
				</div>
				<router-link :to="{name : 'login'}">
					<div class="bg-rose-600 hover:bg-rose-400 p-4 text-white text-center rounded-sm text-sm">
						SIGN OUT
					</div>
				</router-link>
			</div>
			<div class="flex flex-col mt-4 p-4 bg-white rounded-sm">
				<div class="w-full flex justify-between items-center">
					<span class="text-sm">Select the interested field of expertise</span>
					<dropdown placement="bottom" :text="expertiseField" class="dropdown-button ">
						<list-group>
							<list-group-item v-for="field in expertiseFieldList" @click="expertiseField = field">
								<div class="px-4 py-2 bg-slate-100 hover:bg-white ">
									<span>{{field}}</span>
								</div>
							</list-group-item>
						</list-group>
					</dropdown>
				</div>
				<!-- <span class="text-sm">Click profile to view the interview video.</span> -->
				<div class="grid grid-cols-1 gap-2 mt-2">
					<div  v-for="user in userList">
						<ProfileCard :user="user"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { Dropdown } from 'flowbite-vue'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'

import { db } from '../firebase/config'
import ProfileCard from '@/components/ProfileCard.vue'


export default {
	name: 'RecruiterView',
	components: {
		ProfileCard,
		Dropdown
	},
	setup(){
		const userList = ref([]) 

		const expertiseField = ref('Architecture')
		const expertiseFieldList = [
			'Architecture',
			'Engineering and Computer Science',
			'Arts and Entertainment',
			'Business, management and administration',
			'Education',
			'Law & Public Policy',
			'Health and Medicine'
		]

		const getUserList = async () => {
			try {
				const userCollection = await collection(db, 'users')
				const q = query(userCollection, where('expertise_field', '==', expertiseField.value), orderBy('overall_score', 'desc'))
				const querySnapshot = await getDocs(q)
				let userList = []
				querySnapshot.forEach((doc) => {
					userList.push(
						doc.data()
					)
				});
				console.log(userList)
				return userList
			} catch(error) {
				console.log(error.message)
			}
		}

		onMounted( async () => {
			userList.value = await getUserList()
		})

		watch(expertiseField, async () => {
			userList.value = await getUserList()
		})

		return { userList, expertiseField, expertiseFieldList }
	}
}
</script>
