import Vuex from 'vuex'

const store = new Vuex.Store({
	state: {
		userAuth: null
	},
	getters: {
	},
	mutations: {
		setUserAuth(state, data){
			state.userAuth = data
		}
	},
	actions: {
	},
	modules: {
	}
})
export default store

