<template>
	<div class="font-inter text-slate-800 bg-slate-100">
		<router-view/>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import store from '@/store/index'

export default {
	name: 'App',
	setup(){
		const router = useRouter()

		store.watch((state, getters) => [state.userAuth],  () => {
			if (store.state.userAuth != null) {
				router.push({name: 'candidate'})
			} else {
				router.push({name: 'login'})
			}
		})
	}
}
</script>

<style>
body::-webkit-scrollbar {
	display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}


</style>
